<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>My Account Managers</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Search Account Managers"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'cprMyAccountsWithId', params: { id: item.id } }">
            View Accounts
          </router-link>
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status.description }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MyAccountManagers",
  metaInfo: {
    title: "My Account Managers"
  },
  data() {
    return {
      search: "",

      loading: false,
      items: [],
      headers: [
        {
          value: "actions",
          text: "Actions",
          sortable: true
        },
        {
          value: "participantKey",
          text: "SAP #",
          sortable: true
        },
        {
          value: "user.firstName",
          text: "First Name",
          sortable: true
        },
        {
          value: "user.lastName",
          text: "Last Name",
          sortable: true
        },
        {
          value: "status",
          text: "Status",
          sortable: true
        },
        {
          value: "email1",
          text: "Email",
          sortable: true
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = { reportToParticipant: { id: this.selectedParticipant.id } };
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      this.$api
        .post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "My Accounts" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
